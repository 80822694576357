@import url('https://fonts.googleapis.com/css?family=Nunito:regular,bold');
@import '~antd/dist/antd.less';

@heading-color: white;
@primary-color: @primary-green;
// @btn-border-radius-base: 20px;
@font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';
@font-size-base: 18px;
@error-color: @primary-red;
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45);

:root {
  --amplify-font-family: @font-family;
  --amplify-primary-color: @primary-color;
  --amplify-primary-shade: @primary-blue;
  --amplify-primary-tint: @primary-purple;
  --amplify-red: @primary-red;
  font-family: @font-family;
}

amplify-authenticator {
  --container-height: 0;
}

.ant-modal .ant-btn {
  width: 100%;
}

.ant-modal-confirm-body {
  display: flex;
  justify-content: flex-end;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: none;
}

.App {
  text-align: center;
  // background-color: @primary-purple;
  min-height: 100vh;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  // color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-purple: #691EBF;@primary-blue: #349DC7;@primary-green: #2AAF9E;@primary-pink: #BE005D;@primary-red: #E74E68;